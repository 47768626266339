
import { defineComponent } from "vue";
import { getNextCheckboxID } from "./CheckboxStore";

export default defineComponent({
  props: {
    modelValue: Boolean,
    label: String,
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    id: String,
    size: {
      type: String,
      default: '24px'
    }
  },

  data()
  {
    return {
      // Geef een uniek ID aan de outline, die moet namelijk via een ID maar als er checkboxes van meerdere
      // sizes op de page zijn dan geeft dit conflicten en renderen ze verkeerd ook al zijn alle viewboxen gelijk.
      // SVG rendering is weird.
      checkboxID: getNextCheckboxID()
    }
  },

  methods: {
    toggle() 
    {
      if (this.readonly || this.disabled)
        return;

      this.$emit("update:modelValue", !this.modelValue);
    },
  },
});
